import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import BackgroundImage from '../components/backgroundImage';
import Footer from '../components/footer';

const IndexPage = () => (
  <Layout>
    <SEO
      context={{ title: 'Duuk van Dijk' }}
      keywords={[`duuk`, `van dijk`, `site`]}
    />
    <BackgroundImage />
    <section
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: `center`,
        alignItems: `center`,
        flexDirection: `column`,
      }}
    >
      <article>
        <h1>Welkom bij Duuk</h1>
        Mijn plek in cyberspace. 😉
      </article>
      <Footer />
    </section>
  </Layout>
);

export default IndexPage;
